import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Měření úhlů a směrů v geodézii" />
    <h1>Měření úhlů a směrů v geodézii</h1>

    <p><strong>Úhel</strong> (povětšinou myšlen právě <strong>rovinný</strong>) je ta část roviny, kterou svírají dvě polopřímky. Počátek těchto polopřímek se nazývá vrchol úhlu a samotné polopřímky se označují jako ramena úhlu. Rozeznáváme úhel nulový, ostrý, pravý, tupý, přímý, plný. Lze rozlišit i úhly vnitřní a vnější.</p>
    <p><strong>Prostorový</strong> <strong>úhel</strong> je pak část prostoru vymezená rotační kuželovou plochou. Při sférické trigonometrii pak má trojúhelník součet všech úhlů větší než 180 ° (součet všech úhlů rovinného trojúhelníku). Rozdíl mezi součtem všech úhlů sférického trojúhelníka a rovinného trojúhelníka se nazývá <strong>sférický exces</strong> (nadbytek).</p>
    <hr /><h2>Míry úhlové</h2>
    <p>Kromě délkových a plošných jednotek, užívaných výhradně v metrické soustavě, platí dosud 3 typy úhlových měr:</p>
    <ul>
    <li><strong>oblouková</strong></li>
    <li><strong>šedesátinná (sexagesimální)</strong></li>
    <li><strong>setinná (centesimální)</strong></li>
    </ul>
    <p>Úhlovou jednotkou míry <strong>obloukové</strong> je radián (bezrozměrná veličina). Vypočítá se vydělením délky oblouku kruhu poloměrem kruhu. Plnému úhlu odpovídá hodnota 2π, přímému π, pravému π/2.</p>
    <p>Jednotkou <strong>šedesátinné</strong> míry je stupeň, který se dělí na minuty a vteřiny (ty již mají desetinné dělení). Hodnota plného úhlu je 360&nbsp;°, přímého 180&nbsp;° a pravého 90&nbsp;°.</p>
    <p>Jednotkou <strong>setinné</strong> míry je gon (starším označením grad), ten se dělí na setinnou minutu a vteřinu. Je standardem při měření v&nbsp;geodézii. Plný úhel činí 400&nbsp;<sup>g</sup>, přímý 200&nbsp;<sup>g </sup>a pravý 100&nbsp;<sup>g</sup>.</p>
    <hr /><h2>Měření vodorovných a svislých úhlů</h2>
    <p><strong>Vodorovný úhel</strong> svírá dvě svislé roviny, procházející středem úhloměrného přístroje a cílovými body.</p>
    <p><strong>Svislý úhel </strong>se měří ve svislé rovině procházející středem úhloměrného přístroje. Je definován jako úhel mezi základním směrem a směrem na cílový bod. Rozeznávám, respektive měříme:</p>
    <ul>
    <li><strong>zenitové úhly</strong> (základní směr = zenit; hodnoty 0 až 180&nbsp;°)</li>
    <li><strong>svislé úhly </strong>(základní směr = rovina zdánlivého horizontu přístroje; hodnoty +&nbsp;90 až -&nbsp;90&nbsp;°)</li>
    </ul>
    <p>Svislé i vodorovní úhly lze měřit množstvím přístrojů a pomůcek pro měření směrů a úhlů. Nejčastěji se dnes v&nbsp;geodézii měří totální stanicí, popř. teodolitem. Při jejich měření může docházet a dochází k&nbsp;řadě měřičských chyb.</p>
    <hr /><h2>Měření vodorovných směrů metodou ve skupinách</h2>
    <p>Při měření vodorovných směrů teodolitem (či totální stanicí) se často měřívá metodou po skupinách, která omezuje přístrojovou chybu měření z&nbsp;dělení kruhu. Po připravení teodolitu na měření, si vybereme několik bodů v terénu, které budou tvořit osnovu směrů a jejichž viditelnost v terénu je velmi dobrá (modelově body A, B a C). Jeden z&nbsp;bodů zvolíme jako počáteční – v&nbsp;tomto případě bod A.</p>
    <p>Přístroj je pak ustanoven do první polohy (svislý kruh alhidády vlevo od dalekohledu) a je nastaveno čtení na hodnotu blízké nule. Poté je s&nbsp;uzavřenou repetiční svorou zacíleno na bod A, hodnota blízká nule je zapsáno do zápisníku a dojde k&nbsp;povolení repetiční svory. Postupně jsou pak ve směru hodinových ručiček zacíleny body B, C a opět bod A (tím je proveden tzv. uzávěr). Po každém zacílení bodů následuje zápis do příslušné kolonky zápisníku.</p>
    <p>Následně je přístroj uveden do druhé polohy (svislý kruh alhidády vpravo od dalekohledu), v&nbsp;níž je postup opakován. Pohyb přístroje je však prováděn proti směru hodinových ručiček.</p>
    <p>Výše popsaný postup je kompletně opakován, dokud měření neprovedou všechny skupiny (typicky 2 až 4). Při každém dalším měření je hodnota na 1. bodě v&nbsp;první poloze nadsazena o 200/(počet skupin)&nbsp;gonů. Z daných&nbsp;hodnot jsou poté vypočteny redukované průměry a jsou tak zjištěny výsledné centrované směry bodů.</p>
    <hr /><h2>Měření zenitových úhlů teodolitem</h2>
    <p>Při měření zenitových úhlů teodolitem je nutné nejprve připravit přístroj na měření. Daný bod je nejprve zacílen v&nbsp;první (svislý kruh alhidády vlevo od dalekohledu) a pak druhé (kruh vpravo) poloze.</p>
    <p>Poté je vypočtena <strong>indexová chyba</strong> podle vzorce <strong>i = 400 – (zI + zII) / 2 [gon]</strong>, kde zI znamená hodnotu získanou čtením v&nbsp;první poloze a zII čtením v&nbsp;druhé poloze. Tuto indexovou chybu přičtu k&nbsp;výsledku z&nbsp;čtení v&nbsp;první poloze a získávám výsledný zenitový úhel daného bodu.</p>

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Měření úhlů a směrů</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/rozdeleni-geodezie/"><button className="inv">&larr; Rozdělení geodézie</button></Link>
    <Link to="/zaklady-geodezie-gps/pristroje-a-pomucky-pro-mereni-smeru-a-uhlu/"><button className="inv">Nástroje pro měření úhlů a směrů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
